<template>
  <div id="section2">
    <div class="fondoAzul" >
      <b-container fluid>
        <b-container class="text-center">
          <br />
          <h2  class="text-left">
            <div v-for="(item, idx) in payTitulos" :key="idx">
            {{item}}
            </div>
          </h2>
          <br />
            <b-row align-h="center">
              <b-col lg="2" md="4" sm="12" class="text-center"
                v-for="(item, idx) in payList" :key="idx">
                <div class="text-center" >
                    <a
                      class="btn paybtnIcon bordeWhite"
                    >
                      <font-awesome-icon :icon="payListI[idx].btnImg" class="socialIconPay" />
                    </a>
                  <br>
                  <h4>
                    {{item.titulo}}
                  </h4>
                </div>
                <br>
              </b-col>
            </b-row>
          <br />
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonWhite">
              {{$t("payBtn")}}
            </a>
          </div>
          <br />
          <p>
            {{$t("payTxt")}}  
          </p>  
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulos":[
      "Todas estas ventajas para ti."
    ],
    "payList": [
      {
        "titulo": "Soporte técnico y de negocio personalizados"
      },
      {
        "titulo": "Colaboración en oportunidades de negocio"
      },
      {
        "titulo": "Certificación como partner autorizado"
      },
      {
        "titulo": "Campañas de promoción y marketing*"
      },
      {
        "titulo": "Webinars *"
      },
      {
        "titulo": "Promoción en Snap* Partners"
      }
    ],
    "payBtn": "HAZTE PARTNER",
    "payTxt": "*Sujeto a disponibilidad y condiciones particulares."
  },
  "es":{
    "payTitulos":[
      "Todas estas ventajas para ti."
    ],
    "payList": [
      {
        "titulo": "Soporte técnico y de negocio personalizados"
      },
      {
        "titulo": "Colaboración en oportunidades de negocio"
      },
      {
        "titulo": "Certificación como partner autorizado"
      },
      {
        "titulo": "Campañas de promoción y marketing*"
      },
      {
        "titulo": "Webinars *"
      },
      {
        "titulo": "Promoción en Snap* Partners"
      }
    ],
    "payBtn": "HAZTE PARTNER",
    "payTxt": "*Sujeto a disponibilidad y condiciones particulares."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payListI: [
        {
          btnImg: ['fas','wrench']
        },
        {
          btnImg: ['fas','users']
        },
        {
          btnImg: ['fas','certificate']
        },
        {
          btnImg: ['fas','chart-line']
        },
        {
          btnImg: ['fas','video']
        },
        {
          btnImg: ['fas','check-square']
        }
      ],
      payUrl: "page_pay3.1"
    };
  },
  computed: {
    payTitulos() {
      return this.$t("payTitulos");
    },
    payList() {
      return this.$t("payList");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>
