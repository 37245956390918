<template>
  <div id="section6">
    <div class="fondoAzul">
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p class="text-justify">
            {{$t("payText")}}
          </p>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonWhite" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "A rate tailored to your needs.",
    "payText": "At UniversalPay we adapt all our services to your needs. And the rates, too.",
    "payBtn": "CHECK OUT THE RATES"
  },
  "es":{
    "payTitulo": "Centro de logotipos.",
    "payText": "Descarga la imagen de la marca UniversalPay y muestra a tus clientes que pueden realizar pagos con seguridad y confianza.",
    "payBtn": "CENTRO DE LOGOTIPOS"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payUrl: "page_pay3.5"
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>
