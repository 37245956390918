<template>
  <div id="section5">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <br>
          <b-row>
            <b-col md="6" sm="12" v-for="(item, idx) in payItem" :key="idx" class="p-1">
              <div class="fondoFwhite" 
              style="height: 100%; padding: 4em 2.5em;"
              :style="'background-image: url(' + payList[idx].img + ');'">
                <br>
                <h2>
                  {{item.titulo}}
                </h2>
                <h4>
                  {{item.texto}}
                </h4>
                <br>
                <div>
                  <a @click="menuClicked(payList[idx].url)" class="btn buttonTurqueza">
                    {{item.btn}}
                  </a>
                </div>
                <br>
              </div>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payItem": [
      {
        "titulo": "Conoce nuestras soluciones en tienda."
        , "texto": "Cuéntanos tu caso y te referiremos al socio que mejor se adapte a tus necesidades."
        , "btn": "CONCTACTAR"
      },
      {
        "titulo": "Desarrolladores."
        , "texto": "Conoce nuestras ventajas y las posibilidades de integración."
        , "btn": "VER FORMAS DE INTEGRAR"
      }
    ]
  },
  "es":{
    "payItem": [
      {
        "titulo": "Conoce nuestras soluciones en tienda."
        , "texto": "Cuéntanos tu caso y te referiremos al socio que mejor se adapte a tus necesidades."
        , "btn": "CONCTACTAR"
      },
      {
        "titulo": "Desarrolladores."
        , "texto": "Conoce nuestras ventajas y las posibilidades de integración."
        , "btn": "VER FORMAS DE INTEGRAR"
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payList: [
        {
          img: require("@/assets/images/pay/tienda_01_.jpg")
          , url: "page_pay4"
        },
        {
          img: require("@/assets/images/pay/destdesarrolladores_02.jpg")
          , url: "page_pay3.3"
        }
      ]
    }
  },
  computed: {
    payItem() {
      return this.$t("payItem");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>
