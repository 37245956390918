<template>
  <div id="section4">
    <div class="fondoGrisClaro">
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p v-for="(item, idx) in payText" :key="idx">
            {{item}}
          </p>
          <br>
          <b-row align-h="center" align-v="center">
            <b-col md="3" sm="12" v-for="(item, idx) in payList" :key="idx" class="p-1">
              <div class="text-center" :title="item.title">
                <b-img :src="item.img" :alt="item.title"/>
              </div>
            </b-col>
          </b-row>
          <br>
          <div class="text-center">
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza">
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Integración para las tiendas de ECommerce.",
    "payText": [
      "Contamos con plugins para las principales plataformas de comercio electrónico."
    ],
    "payBtn": "MÁS INFORMACIÓN"
  },
  "es":{
    "payTitulo": "Integración para las tiendas de ECommerce.",
    "payText": [
      "Contamos con plugins para las principales plataformas de comercio electrónico."
    ],
    "payBtn": "MÁS INFORMACIÓN"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payList: [
        {
          title: "PALBIN"
          , img: require("@/assets/images/pay/partners/palbin.png")
        },
        {
          title: "PRESTASHOP"
          , img: require("@/assets/images/pay/partners/prestashop.png")
        },
        {
          title: "MAGENTO"
          , img: require("@/assets/images/pay/partners/magento.png")
        },
        {
          title: "WOOCOMMERCE"
          , img: require("@/assets/images/pay/partners/woocommerce.png")
        }
      ],
      payUrl: "page_pay3.2"
    }
  },
  computed: {
    payText() {
      return this.$t("payText");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>
