<template>
  <div id="section3">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p v-for="(item, idx) in payText" :key="idx">
            {{item}}
          </p>
          <br>
          <b-row align-h="center" align-v="center">
            <b-col md="4" sm="12" v-for="(item, idx) in payList" :key="idx" class="p-1">
              <div class="text-center" :title="item.title">
                <b-img :src="item.img" :alt="item.title"/>
              </div>
            </b-col>
          </b-row>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza">
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Encuentra un partner.",
    "payText": [
      "Encuentra el socio que necesitas para cubrir tus necesidades y empezar a aceptar pagos de forma rápida y segura.",
      "Desde agencias, plataformas, integradores, soluciones integrales y más."
    ],
    "payBtn": "VER TODOS"
  },
  "es":{
    "payTitulo": "Encuentra un partner.",
    "payText": [
      "Encuentra el socio que necesitas para cubrir tus necesidades y empezar a aceptar pagos de forma rápida y segura.",
      "Desde agencias, plataformas, integradores, soluciones integrales y más."
    ],
    "payBtn": "VER TODOS"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payList: [
        {
          title: "GLOP"
          , img: require("@/assets/images/pay/partners/glop.png")
        },
        {
          title: "POWER SHOP"
          , img: require("@/assets/images/pay/partners/powershop.png")
        },
        {
          title: "EL CORTE INGLES"
          , img: require("@/assets/images/pay/partners/elcorteingles.png")
        }
      ],
      payUrl: "page_pay3.2"
    }
  },
  computed: {
    payText() {
      return this.$t("payText");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>
